.viewport-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow:hidden;

  .viewport-background {
    position: absolute;
    height: 100%;
    filter: brightness(0.5);
  }
}