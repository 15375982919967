.last-wins {
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
        height: 4vw !important;
        color: #ffd300;
        margin-top: 10px !important;
        background-color: #232f4e !important;
    }

    .winners-box {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
    }
    
    > .title,
    .winners-box > div {
        width: 85%;
        border-radius: 8px;
        margin: 0 auto;
        text-align: center;
        border: 1px solid #000000;
        background-color: #31416c;
        box-sizing: border-box;
        padding: 5px;
        height: 8vh;

        p {
            font-size: 0.9vw;
            margin: 0 0 5px 0;
            text-align: right;
            color: #ffd300;

            span {
                font-weight: bold;
            }
        }

        object {
            width: 1.8vw;
        }
    }

    > div:first-child,
    .winners {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}
