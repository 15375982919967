@import "../../../styles/variables";

$iconsSize: 17px;
$mainBackgroundColor: rgba(0, 0, 0, 0.5);
$backgroundColor: black;

.video-item {
  width: 100%;
  height: auto;
  z-index: 10;
  position: relative;

  .player-container {
    display: flex;
    height: 100vh;
    align-items: center;
    background-color: $backgroundColor !important;

    .video-player {
      display: flex;
      width: 100%;
      max-width: 100%;

      &.loaded {
        padding-bottom: 0;
        animation: 0.5s ease-in 0s 1 normal none running fadeIn;
      }
    }
  }

  .video-preloader {
    width: 55px;
    height: 55px;
    display: flex;
    position: absolute;
    top: 38%;
    left: 45%;
    border-radius: 50%;
    background: transparent;
    border-top: 3px solid black;
    border-right: 3px solid transparent;
    animation: 0.5s spin linear infinite;
  }

  video {
    outline: none;
    background-color: $backgroundColor !important;
  }

  .video-controls {
    height: 30px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    background-color: $mainBackgroundColor;
    bottom: 30px;
    opacity: 1;
    transition: opacity 0.5s;

    &.hide {
      opacity: 0;
    }
  }

  .control-icon {
    width: $iconsSize;
    height: $iconsSize;
    margin-top: 6px;
    margin-right: 7px;
    margin-left: 5px;
    float: right;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .video-quality {
    bottom: 30px;
    right: 0;
    position: absolute;
    visibility: hidden;
  }

  .quality-icon {
    width: 30px;
    height: 19px;
    font-size: 13px;
    background-color: $mainBackgroundColor;
    cursor: pointer;
    padding-top: 5px;

    &:hover {
      opacity: 0.8;
    }
  }

  .video-volume-slider {
    width: 85px;
    height: 3px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    margin-top: 13px;
    margin-right: 6px;
    -webkit-appearance: none;

    &:hover {
      opacity: 0.8;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  // TENNIS STYLES

  .tennis-info {
    width: 92%;
    height: 72%;
    margin: auto;
    position: absolute;
    top: 2%;
    left: 4%;

    .start-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      top: 4%;

      .players-block {
        display: flex;
        position: relative;
        justify-content: space-between;
        width: 100%;
        height: 55%;
        margin-bottom: 10px;

        .p-block {
          width: 30%;
          height: 100%;
          /*background-color: rgba(97, 97, 97, 0.5);
          -webkit-box-shadow: 0 0 8px 3px rgba(0,0,0,0.7);
          -moz-box-shadow: 0 0 8px 3px rgba(0,0,0,0.7);
          box-shadow: 0 0 8px 3px rgba(0,0,0,0.7);*/

          &.right {
            border-left: 25px solid transparent;
            border-right: 0;
          }

          .p-name {
            display: flex;
            position: absolute;
            justify-content: center;
            width: 37%;
            height: 26px;
            line-height: 26px;
            font-size: 13px;
            bottom: 0;
            font-weight: bold;
            text-shadow: 1px 1px #262626;

            &.left {
              background: rgba(0, 87, 50, 0.7);
            }

            &.right {
              background: rgba(0, 44, 107, 0.7);
              right: 0;
            }

            p {
              line-height: 26px;
            }
          }

          img {
            width: 100%;
          }
        }
      }

      .game-info {
        display: flex;
        width: 100%;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.5);
        text-shadow: 1px 1px #262626;
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: bold;

        .p-info {
          width: 30%;
          height: 100%;

          p {
            margin: auto;
          }

          .p-number {
            display: flex;
            height: 25px;
            text-align: center;
            font-size: 14px;
            padding-bottom: 2px;
            justify-content: center;

            &.left {
              background: rgb(0, 128, 87);
              background: linear-gradient(180deg, rgb(0, 128, 87) 0%, rgba(0, 63, 41, 1) 100%);
            }

            &.right {
              background: rgb(0, 89, 157);
              background: linear-gradient(180deg, rgb(0, 89, 157) 0%, rgba(0, 49, 90, 1) 100%);
            }
          }

          .p-info-list {

            p {
              margin-top: 5%;
              margin-bottom: 5%;
            }
          }
        }

        .match-info {
          width: 40%;

          p {
            margin-top: 3.5%;
            margin-bottom: 3.5%;
          }
        }
      }
    }

    .process-info {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;

      .scores-info {
        position: absolute;
        top: 5%;
        left: 0;
        background-color: #000000;
        border-radius: 5px;
        border: 2px solid #a0bac3;
        font-weight: bold;
        font-size: 0.7vw;
        transform: scale(0.88);

        .players-scores {
          width: 100%;
          height: 22px;
          line-height: 20px;
          font-size: 11px;
          display: flex;
          align-items: center;

          &.p1 {
            border-bottom: 2px solid #a0bac3;
          }

          &.p2 {

          }

          .name {
            width: 60%;
            white-space: pre;
            text-align: left;
            padding-left: 5px;
          }

          .ball-icon {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            margin-left: 8px;
            background: rgb(230,218,0);
            background: linear-gradient(180deg, rgba(230,218,0,1) 0%, rgb(127, 138, 53) 100%, rgba(2,0,36,1) 000326%);
          }
        }

        .game-score-info {
          display: flex;
          margin-left: 8px;

          .score {
            width: 25px;
            height: 14px;
            line-height: 14px;
            font-size: 11px;
            margin-bottom: 2px;
            margin-right: 2px;
            border-radius: 3px;
            border: 2px solid #a0bac3;
            margin-top: 2px;

            &.set-score {
              background-color: #00794b;
            }

            &.match-score {
              background-color: #7700A8;
            }
          }

          .game-score {
            //width: 15px;
          }
        }
      }
    }

    .final-info {
      display: flex;
      width: 100%;
      height: 55%;
      margin-top: 12%;
      position: relative;

      .name-block {
        display: flex;
        width: 90%;
        height: 23%;
        justify-content: center;
        border-radius: 2px;
        position: relative;
        margin-top: 42.5%;
        font-size: 0.8vw;
        font-weight: bold;
        white-space: pre;
        text-shadow: 2px 2px #393939;

        background-color: rgba(97, 103, 123, 0.6);
        -webkit-box-shadow: 0 0 8px 3px rgba(14, 14, 14, 0.85);
        -moz-box-shadow: 0 0 8px 3px rgba(14, 14, 14, 0.85);
        box-shadow: 0 0 8px 3px rgba(14, 14, 14, 0.85);

        &.left {
          float: left;
        }

        &.right {
          float: right;
        }

        p {
          margin: auto 2px;
        }

        .flag {
          margin: 5%;
        }

        .win-icon {
          position: absolute;
          width: 28%;
          top: -10px;

          &.l {
            left: -24px;
          }

          &.r {
            right: -24px;
          }
        }
      }

      .match-result {
        width: 32%;
        height: 100%;
        font-weight: bold;

        .container {
          width: 100%;
          margin-top: 10%;
          height: 55%;
          background-color: rgba(97, 103, 123, 0.6);
          -webkit-box-shadow: 0 0 8px 4px rgba(40, 40, 40, 0.8);
          -moz-box-shadow: 0 0 8px 4px rgba(34, 34, 34, 0.8);
          box-shadow: 0 0 8px 4px rgba(31, 31, 31, 0.8);

          .header {
            height: 30%;
            font-size: 0.8vw;
            background-color: #0A1320;
            display: flex;
            justify-content: flex-end;

            .list {
              display: flex;
              width: 50%;
              justify-content: space-around;

              p {
                margin: auto;
              }
            }
          }

          .match-result-content {
            width: 100%;
            height: 70%;
            font-size: 0.85vw;

            .scores-block {
              display: flex;
              width: 100%;
              height: 50%;
              text-shadow: 1.5px 1.5px #212121;

              .flag {
                width: 11%;
                margin: auto;
              }

              .player-name {
                margin: auto;
                white-space: pre;
                line-height: 25px;
              }

              .scores-list {
                display: flex;
                width: 50%;
                justify-content: space-around;

                p {
                  margin: auto;
                }

                .result-score {
                  margin: 0;
                  width: 24%;
                  display: flex;
                  text-align: center;
                  align-items: center;
                  justify-content: center;
                  background: rgb(28,107,56);
                  background: linear-gradient(0deg, rgb(30, 109, 59) 0%, rgb(42, 133, 74) 46%, rgba(50,154,87,1) 92%);
                }
              }
            }
          }
        }
      }

      .player1-info, .player2-info {
        width: 38%;
        position: relative;

        .player-img {
          width: 55%;
          position: absolute;
          top: -5.5%;
          left: 18%;
        }
      }

      .player2-info {
        .player-img {
          left: 26%;
        }
      }
    }

    .cat-out {
      top: 0;
      position: absolute;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;
      height: 100%;

      .cat-out-container {
        margin-top: 9%;
        font-weight: bold;
        font-size: 2.1vw;
        transition: .5s linear;
        letter-spacing: 2px;
        color: #ffffff;
        animation-delay: 1s;
        animation: 2s fadeIn linear;
        text-shadow: 0 0 5px #ddeca8, 0 0 15px #585a39, 0 0 25px #000000;
      }
    }
  }
}




