@import "../../styles/variables.scss";

.game-container {
  width: 85%;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  &-content {
    display: flex;
    margin-top: 20px;

    .left-site {
      width: 85%;
    }

    .right-site {
      width: 15%;
      margin-left: 10px;
      border-radius: 8px;
      background-color: #1b2134;
    }
  }

  .place-bets-text.animate {
    color: red;
    font-weight: bold;
    animation: animateText 1s ease;
    animation-iteration-count: 55;
  }

  @keyframes animateText {
    from {
      color: white;
    }
    to {
      color: red;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 10px;
    background-color: #232f4e;
    border-bottom: 3px solid $mainColor;
    border-radius: 5px 5px 0 0;

    &-round-id,
    &-start-time span:nth-child(2) {
      background-color: #314780;
      border: 1px solid #4e78ea;
      border-radius: 8px;
      padding: 10px;
      height: 15px;
      line-height: 0.9;
      color: $mainColor;
    }

    &-start-time span:nth-child(2) {
      padding: 10px 15px;
      margin-left: 10px;
    }

    p {
      margin: 0;
    }

    h4 {
      font-weight: 500;
    }
  }
}
