@import "../../../styles/variables.scss";

.market-content {
    box-shadow: 0 5px 10px -2px #141313;

    &-header {
        display: flex;
        margin-top: 5px;
        height: 7vh;
        border-radius: 15px;

        > p {
            width: 15%;
            margin: 0;
            text-align: center;
            background-color: #232f4e;
            border-right: 1px solid #000000;
            position: relative;
            color: $mainColor;
            font-size: 1.2vw;
            text-shadow: 0 1px 14px #d7ee84;
            border-bottom: 2px solid #00000087 !important;
            box-shadow: inset 0 0 2px 0 rgba(255, 255, 255, 0.4), inset 0 0 3px 0 rgba(0, 0, 0, 0.4),
                inset 0 0 3px 5px rgba(0, 0, 0, 0.05), 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
            box-sizing: border-box;

            &:first-child {
                width: 30%;
                padding-left: 10px;
                justify-content: start !important ;
                border-radius: 8px 0 0 0;
            }

            &:last-child {
                border-radius: 0 8px 0 0;
            }

            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                height: 3px;
                background: #495b8566;
                box-shadow: 0 1px 2px 0 rgba(73, 91, 100, 0.4);
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }
        }
    }

    .coefficient p,
    .market-content-header > p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2vw;
    }

    &-body {
        display: flex;

        p:not(:last-child) .market-content-header > p,
        p:not(:last-child) {
            border-bottom: 2px solid #000000ab;
        }

        p {
            border-right: 1px solid #000000ab;
        }

        .coefficient {
            width: 15%;
            p {
                margin: -1px 0;
                height: 8vh;
                color: #ffe785;
                background-color: #31416c;
                border-bottom: 2px solid #000000ab;
                box-sizing: border-box;

                &.maxValue,
                &.minValue {
                    color: #ffffff;
                    font-weight: bold;
                }

                &.maxValue {
                    background-image: linear-gradient(#7af37a, #3ec13e, #0d980d) !important;
                }

                &.minValue {
                    background-image: linear-gradient(#f85858, #e12f2e, #cb0a0a) !important;
                }
            }
        }
        .player-names {
            width: 30%;

            p {
                display: flex;
                align-items: center;
                color: #85a7ff;
                font-weight: 700;
                background-color: #232f4e;
                margin: -1px 0;
                height: 8vh;
                padding: 10px;
                box-sizing: border-box;

                embed {
                    width: 2vw;
                    margin-right: 8px;
                }
            }
        }
    }
}
