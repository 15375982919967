@import "../../../styles/variables.scss";

$radius: 7px;

.down-counter-container {
  width: 250px;
  height: 7.011vw;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  border-radius: $radius;
  background: #1b25428c;
  position: relative;

  .counter {
    font-size: 40px;
    font-weight: 600;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-width: $radius;
    background: linear-gradient(120deg, $mainColor, #0212ad, #00f5d4);
    background-size: 300% 300%;
    clip-path: polygon(0% 100%, 4px 100%, 4px 4px, calc(100% - 4px) 4px, calc(100% - 4px) calc(100% - 4px), 4px calc(100% - 4px), 4px 100%, 100% 100%, 100% 0%, 0% 0%);
  }

  &:after {
    border-radius: $radius;
    animation: frame-enter 2s forwards ease-in-out reverse, gradient-animation 8s ease-in-out infinite;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 15% 0;
  }
  50% {
    background-position: 85% 100%;
  }
  100% {
    background-position: 15% 0;
  }
}

@keyframes frame-enter {
  0% {
    clip-path: polygon(0% 100%, 4px 100%, 4px 4px, calc(100% - 4px) 4px, calc(100% - 4px) calc(100% - 4px), 4px calc(100% - 4px), 4px 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  25% {
    clip-path: polygon(0% 100%, 4px 100%, 4px 4px, calc(100% - 4px) 4px, calc(100% - 4px) calc(100% - 4px), calc(100% - 4px) calc(100% - 4px), calc(100% - 4px) 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(0% 100%, 4px 100%, 4px 4px, calc(100% - 4px) 4px, calc(100% - 4px) 4px, calc(100% - 4px) 4px, calc(100% - 4px) 4px, calc(100% - 4px) 4px, 100% 0%, 0% 0%);
  }
  75% {
    -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 0%, 0% 0%);
  }
  100% {
    -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 0% 100%);
  }
}